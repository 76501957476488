export const getEnvUrlPart = () => {
  if (
    process.env.NEXT_PUBLIC_CORE_URL &&
    process.env.NEXT_PUBLIC_CORE_URL.includes("dev.")
  ) {
    return "dev.";
  }
  if (
    process.env.NEXT_PUBLIC_CORE_URL &&
    process.env.NEXT_PUBLIC_CORE_URL.includes("staging.")
  ) {
    return "staging.";
  }

  return "";
};
