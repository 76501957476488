"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  background: rgba(0, 0, 0, 0.55);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(80px);
  border-radius: 12px;
  padding: 23px;
  width: 100%;
  max-width: 100%;
`;

const Silver = css`
  width: fit-content;
`;

const Gold = css`
  min-width: 287px;
`;

const Platinum = css``;

const Diamond = css`
  padding: 31px;
  min-width: 403px;
`;

export const SCompSmallActionBoxWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
