"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody03,
  CSSBody03XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  margin-top: 4px;
  color: var(--text-10);
  ${CSSBody03}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  ${CSSBody03XLarge}
`;

export const SCompSmallActionBoxRichContentWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
