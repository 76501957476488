"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  width: 100%;
  padding: 0 20px;
  transform: translateY(-40px);
  display: flex;
  justify-content: center;
  z-index: 1;
  top: 40px;
  pointer-events: none;
  > * {
    pointer-events: all;
  }
`;

const Silver = css`
  position: absolute;
  justify-content: flex-end;
  right: 0px;
  top: 140px;
  transform: translateY(calc(-100% - 40px));
`;

const Gold = css`
  right: 27px;
  top: 0px;
`;

const Platinum = css``;

const Diamond = css`
  right: 24px;
`;

export const SCompSmallActionBox = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
