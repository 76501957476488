"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISFooter {
  $lastSectionIsOverlap: boolean;
}

const Bronze = css<ISFooter>`
  padding-top: ${({ $lastSectionIsOverlap }) =>
    $lastSectionIsOverlap ? "54px" : "24px"};
  padding-bottom: 20px;
  background: var(--ui-01);
`;

const Silver = css<ISFooter>``;

const Gold = css<ISFooter>`
  padding-top: ${({ $lastSectionIsOverlap }) =>
    $lastSectionIsOverlap ? "166px" : "86px"};
  padding-bottom: 26px;
`;

const Platinum = css<ISFooter>``;

const Diamond = css<ISFooter>``;

export const SFooter = styled.div<ISFooter>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
