"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  display: flex;
  flex-wrap: wrap;
  gap: 40px 0;
  margin-top: 24px;
`;

const Silver = css``;

const Gold = css`
  margin-top: 0;
  /* gap: 16px; let go with 40 since we have not much content yet */
  gap: 40px;
  margin-left: 58px;
  margin-right: 24px;
`;

const Platinum = css``;

const Diamond = css``;

export const SFooterSections = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
